<template>
  <div class="register">

    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <h1 class="page-title">Create an account</h1>

        <h3>Login information</h3>
        <p class="login-cta">
          Have an account?
          <router-link to="/login">Click here</router-link>
        </p>

        <b-form autocomplete="off" @submit.stop.prevent="onFormSubmit">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Name / Chosen name" class="custom">
                <b-form-input v-model="form.name" trim required />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Last Name" class="custom">
                <b-form-input v-model="form.last_name" trim required />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Email" class="custom">
                <b-form-input v-model="form.email" type="email" trim />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Confirm Email" class="custom">
                <b-form-input v-model="form.email_confirmation" type="email" trim />
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b-form-group label="Password" class="custom">
                <b-form-input v-model="form.password" type="password" trim />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b-form-group label="Confirm Password" class="custom">
                <b-form-input v-model="form.password_confirmation" type="password" trim />
              </b-form-group>
            </div>
          </div>


          <h4>Billing address</h4>
          <div class="row">
            <div class="col-8">
              <b-form-group label="Street address" class="custom">
                <b-form-input v-model="form.address_street" trim required />
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group label="Apt, ste, bldg." class="custom">
                <b-form-input v-model="form.address_complement" trim />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <b-form-group label="City" class="custom">
                <b-form-input v-model="form.address_city" trim required />
              </b-form-group>
            </div>
            <div class="col-3">
              <b-form-group label="Country" class="custom">
                <!-- <b-form-input v-model="form.address_country" trim required /> -->
                <b-form-select
                  v-model="form.address_country"
                  :options="countries"
                  class="form-control"
                  text-field="text"
                  value-field="value"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <b-form-group label="Zipcode" class="custom">
                <b-form-input v-model="form.address_zipcode" trim required />
              </b-form-group>
            </div>
            <div class="col-8" v-if="false">
              <b-form-group label="Mobile">
                <!-- <b-form-input v-model="form.mobile" trim required /> -->
                <VuePhoneNumberInput
                  v-model="form.mobile"
                  name="mobile_country"
                  @update="onUpdateMobileCountry"
                  id="mobile_country"
                  :default-country-code="form.mobile_country"
                />
              </b-form-group>
            </div>
          </div>

          <b-form-checkbox
            required
            v-model="form.accept_tos"
            :value="true"
            :unchecked-value="false"
          >
            I agree to the Whole Festival <a href="/legal-notice">Privacy Policy</a>.
          </b-form-checkbox>

          <div class="row justify-content-end">
            <div class="col-12 col-md-4">
              <b-overlay
                :show="busy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-btn variant="primary" type="submit" block :disabled="busy">
                  CREATE ACCOUNT
                </b-btn>
              </b-overlay>
            </div>
          </div>

        </b-form>

      </div>
    </div>

  </div>
</template>

<script>
import Toast from '@/mixin/toast';
import AuthService from '@/services/auth.service';

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import countries from "./../json/countries.json";

export default {
  name: 'Register',
  metaInfo: {
    title: 'Create an account',
  },
  mixins: [ Toast ],
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      busy: false,
      form: {
        name: '',
        last_name: '',
        email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        address_street: '',
        address_complement: '',
        address_city: '',
        address_country: '',
        address_zipcode: '',
        mobile: '',
        mobile_country: "",
        accept_tos: false,
      }
    }
  },
  computed: {
    countries: function () {
      return countries.sort();
    },
  },
  methods: {
    onUpdateMobileCountry(payload) {
      this.form.mobile_country = payload.countryCode;
    },
    onFormSubmit() {
      this.busy = true;
      AuthService.register(this.form).then((result) => {        
        if(result.status == 200){
          this.$router.push(`/confirm_email/${this.form.email}` );
        }else{
          this.showToast('danger', 'Error', result.data.errors[0]);
        }       
        this.busy = false;
      }, (error) => {
        this.busy = false;
        error.data.errors[0] && this.showToast('danger', 'Error', error.data.errors[0]);
      })
      
    }
  }
}
</script>

<style lang="scss">
.register {
  .page-title {
    margin: 6rem 0 3rem;
    font-size: 47px;
    font-weight: 700;
  }

  h3 {
    font-size: 29px;
    font-weight: 700;
  }

  .login-cta {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .form-group {
    margin: 0.7rem 0;

    legend {
      text-transform: uppercase;
      color: var(--whole-form-legend-color);;
      font-size: 14px;
      letter-spacing: 0.05em;
    }

    .form-control {
      border-color: var(--whole-text);
      background: var(--whole-black-form-bg);
      font-size: 18px;
      color: var(--whole-text);
    }
  }

  h4 {
    font-weight: bold;
    font-size: 29px;
    line-height: 120%;
    margin-top: 2rem;
  }

  .custom-control.custom-checkbox {
    margin: 1rem 0 2rem;
  }

  .btn.btn-block.btn-primary {
    border-radius: 0;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
  }

  .address_country .flex-1 {
    display: none;
  }

  .country-selector__input,
  .address_country .select-country-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .country-selector__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  .input-tel__input {
    border-left: 1px solid var(--whole-text-opacity) !important;
  }

  .country-selector__input,
  .input-tel__input {
    border-color: var(--whole-text);
    background: var(--whole-black-form-bg);
    font-size: 18px;
    color: var(--whole-text);
  }
}
</style>
